import * as React from "react";

import Tippy, { type TippyProps } from "@tippyjs/react";
import type { Instance } from "tippy.js";

import { SiteLink } from "@parataxic/shared-ui";
import { fetchPageData } from "@parataxic/shared-ui/src/lazy-pagedata";

import { BookCard } from "./Book";
import { PsychologistCard } from "./Psychologist";
import { TheoryCard } from "./Theory";

import "@parataxic/shared-ui/src/styles/Popover.css";

// Export own set of props (even if they are the same for now) to enable clients to be more future-proof
interface IPopoverFetchCallback<PageData> {
  data: PageData & { mdx?: GatsbyTypes.Mdx };
  setContent: React.Dispatch<React.SetStateAction<string | React.ReactElement>>;
  setMounted: React.Dispatch<React.SetStateAction<boolean>>;
  contentRef: React.RefObject<HTMLDivElement>;
}

export type LazyTippyProps = TippyProps & {
  fallback?: JSX.Element;
  onHit?: (arg0: IPopoverFetchCallback<{ [name: string]: unknown }>) => void;
  fetcher?: (linkUrl: string) => Promise<{ [name: string]: unknown }>;
};

interface IPopoverInstance extends Instance {
  _isFetching: boolean;
  error: string | null;
}

interface IPageData {
  video?: GatsbyTypes.MdxVideo;
  psychologist?: GatsbyTypes.MdxPsychologist;
  theory?: GatsbyTypes.MdxTheory;
  book?: GatsbyTypes.MdxBook;
}

const parataxicOnHit = ({
  contentRef,
  data,
  setContent,
  setMounted,
}: IPopoverFetchCallback<IPageData>) => {
  if (data.theory && data.mdx) {
    setContent(
      <div
        style={{
          backgroundColor: "var(--color-gray-700)",
          fontStyle: "normal",
          borderRadius: ".5rem",
        }}
        ref={contentRef}
      >
        <TheoryCard theory={data.theory} mdx={data.mdx} />
      </div>,
    );
    setMounted(true);
  }
  if (data.psychologist) {
    setContent(
      <div
        ref={contentRef}
        style={{
          backgroundColor: "var(--color-gray-700)",
          fontStyle: "normal",
          borderRadius: ".5rem",
        }}
      >
        <PsychologistCard person={data.psychologist} />
      </div>,
    );
    setMounted(true);
  }
  if (data.book) {
    setContent(
      <div
        ref={contentRef}
        style={{
          backgroundColor: "var(--color-gray-700)",
          fontStyle: "normal",
          borderRadius: ".5rem",
        }}
      >
        <BookCard book={data.book} />
      </div>,
    );
    setMounted(true);
  }
};

export const LazyTippyBase: React.FC<LazyTippyProps> = ({
  fallback = "Loading...",
  onHit,
  fetcher,
  ...props
}) => {
  const [mounted, setMounted] = React.useState(false);
  const [content, setContent] = React.useState(fallback);
  const contentRef = React.useRef(null);
  const onShow = React.useCallback(
    (instance: IPopoverInstance) => {
      // eslint-disable-next-line
      const href = props?.children?.props?.href;
      if (instance._isFetching || instance.error || !href) {
        return;
      }
      if (content !== fallback) {
        setMounted(true);
        return void 0;
      }
      instance._isFetching = true;

      // Check to see if JSON file exists in CDN
      fetcher(href)
        .then((data: IPopoverFetchCallback<unknown>["data"]) => {
          onHit?.({
            data,
            setMounted,
            setContent,
            contentRef,
          });
        })
        .catch((error) => {
          instance.error = JSON.stringify(error);
          instance.setContent(`Request failed. ${instance.error}`);
        })
        .finally(() => {
          instance._isFetching = false;
        });
      return;
    },
    [content, mounted],
  );
  const onCreate = (instance: IPopoverInstance) => {
    instance._isFetching = false;
    instance.error = null;
  };
  const onHidden = (instance: IPopoverInstance) => {
    instance.error = null;
    setMounted(false);
  };
  const computedProps = { ...props };
  computedProps.plugins = props.plugins || [];
  computedProps.content = mounted ? content || props.content : "";

  return (
    <Tippy
      {...computedProps}
      onShow={onShow}
      onCreate={onCreate}
      onHidden={onHidden}
      duration={0}
      delay={[500, 100]}
      interactive={true}
    />
  );
};

export const PopoverSiteLinkBase: React.FC<
  React.ComponentProps<typeof SiteLink> & {
    tippyProps?: TippyProps;
  } & Pick<LazyTippyProps, "onHit" | "fetcher">
> = ({ fetcher, onHit, tippyProps, ...props }) => (
  <LazyTippyBase {...tippyProps} onHit={onHit} fetcher={fetcher}>
    <SiteLink {...props} />
  </LazyTippyBase>
);

export const PopoverSiteLink: React.FC<
  React.ComponentProps<typeof PopoverSiteLinkBase>
> = ({ tippyProps, ...props }) => (
  <PopoverSiteLinkBase
    tippyProps={tippyProps}
    {...props}
    onHit={parataxicOnHit}
    fetcher={fetchPageData}
  />
);
