import * as React from "react";

import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";

import { LinkBox, SiteLink } from "@parataxic/shared-ui";

import { TheoryCreatorList } from "./Theory";

import "./Component.css";

export const BookBoxItem: React.FC<
  {
    readonly book: GatsbyTypes.Maybe<GatsbyTypes.MdxBook>;
  } & React.ComponentProps<typeof LinkBox>
> = ({ book, ...rest }) => {
  if (!book) {
    return null;
  }
  return (
    <LinkBox href={book.slug} boxProps={rest}>
      {book.title}
    </LinkBox>
  );
};

export const BookList: React.FC<{
  books: readonly GatsbyTypes.Maybe<GatsbyTypes.MdxBook>[];
}> = ({ books }) => {
  if (!books?.length) {
    return null;
  }

  return (
    <div role="list">
      {books.map((book, idx) => (
        <BookBoxItem book={book} key={idx} role="listitem" />
      ))}
    </div>
  );
};

export const BookCard: React.FC<{
  book: GatsbyTypes.MdxBook;
}> = ({ book }) => {
  if (!book?.length) {
    return null;
  }
  return (
    <div className="book-card">
      {book?.coverImage && (
        <div className="book-card__image-grid">
          <Img image={getImage(book?.coverImage)} />
        </div>
      )}
      <div className="book-card__grid">
        {book?.authors?.length > 0 && (
          <TheoryCreatorList
            className="book-card__grid__theory-creator-list"
            persons={book?.authors}
            linkProps={{
              className: "book-card__grid__theory-creator-list__link",
            }}
          />
        )}
        <div className="book-card__grid__content">
          <SiteLink href={book.slug} className="book-card__grid__content__link">
            {book.title}{" "}
          </SiteLink>
        </div>
      </div>
    </div>
  );
};

export const BookBox: React.FC<{
  books: readonly GatsbyTypes.Maybe<GatsbyTypes.MdxBook>[];
  title?: string;
}> = ({ books, title }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const onToggle = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);
  if (!books?.length) {
    return null;
  }

  return (
    <div className="book-box">
      <h5 className="book-box__header">
        {title ?? "Books"} ({books.length})
        <span onClick={onToggle} className="book-box__header__text">
          {isOpen ? "[-] close" : "[+] open"}
        </span>
      </h5>{" "}
      {isOpen && <BookList books={books} />}
    </div>
  );
};
