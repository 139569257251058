module.exports = [{
      plugin: require('../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"parataxic","short_name":"parataxic","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"static/img/site/logo-white-rounded.png","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/home/runner/work/parataxic.org/parataxic.org/www/src/templates/MDXPage.tsx"},"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/parataxic.org/parataxic.org/node_modules/gatsby-remark-numbered-footnotes","id":"4c0909b1-1583-5ca8-8913-9ca44e3102d1","name":"gatsby-remark-numbered-footnotes","version":"1.0.1","modulePath":"/home/runner/work/parataxic.org/parataxic.org/node_modules/gatsby-remark-numbered-footnotes/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/runner/work/parataxic.org/parataxic.org/node_modules/gatsby-remark-mermaid","id":"7a3a0615-7e85-5c40-92c7-0b58aab29318","name":"gatsby-remark-mermaid","version":"2.1.0","modulePath":"/home/runner/work/parataxic.org/parataxic.org/node_modules/gatsby-remark-mermaid/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx"],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/parataxic.org/parataxic.org/www","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[],"excludeRegex":{},"excludePattern":"^(#)"},
    },{
      plugin: require('../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://39c7eed0f5c3469a9857d74b87364837@o96178.ingest.sentry.io/6162991","sampleRate":0.7},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-162768239-1"],"gtagConfig":{"cookieExpires":0},"pluginConfig":{"exclude":["//offline-plugin-app-shell-fallback/**"],"defer":true}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-web-vitals/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-162768239-1","metrics":["FID","TTFB","LCP","CLS","FCP"],"eventCategory":"Performance","includeInDevelopment":false,"debug":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
