import * as React from "react";

import { useBreakpoint } from "use-breakpoint";

import "../styles/Card.css";

export type CardVariants =
  | "outlined"
  | "full"
  | "fullFlush"
  | "elevated"
  | "searchResult";

export const Card = ({
  variant = "elevated",
  className,
  ...props
}: {
  variant?: CardVariants;
} & React.HTMLProps<HTMLDivElement>): React.ReactElement => {
  return (
    <div {...props} className={`card ${variant ?? ""} ${className ?? ""}`} />
  );
};

// IMPORTANT: Keep this on the outside
const BREAKPOINTS = { full: 0, elevated: 768 };

export const ResponsiveCard = (
  props: React.ComponentProps<typeof Card>,
): React.ReactElement => {
  const { breakpoint: responsiveVariant } = useBreakpoint(
    BREAKPOINTS,
    "elevated",
  );

  return <Card {...props} variant={responsiveVariant} />;
};

export default Card;
