import * as React from "react";
import ReactFlow, {
  addEdge,
  ReactFlowProvider,
  removeElements,
} from "react-flow-renderer";

const freudPsychosexualStages = [
  {
    id: "1",
    data: {
      label: <>Oral</>,
    },
    position: { x: 100, y: 0 },
  },
  {
    id: "2",
    data: { label: "Anal" },
    position: {
      x: 100,
      y: 60,
    },
  },
  { id: "3", data: { label: "Phallic" }, position: { x: 100, y: 120 } },
  { id: "4", data: { label: "Latency" }, position: { x: 100, y: 180 } },
  { id: "5", data: { label: "Genital" }, position: { x: 100, y: 240 } },
  {
    id: "e1-2",
    source: "1",
    target: "2",
  },
  { id: "e2-3", source: "2", target: "3" },
  { id: "e3-4", source: "3", target: "4" },
  { id: "e4-5", source: "4", target: "5" },
];

export const FreudPsychosexualFlow: React.FC = () => {
  const [elements, setElements] = React.useState(freudPsychosexualStages);
  const onConnect = (params) => setElements((els) => addEdge(params, els));
  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));

  return (
    <ReactFlowProvider>
      <div className="reactflow-wrapper" style={{ height: "60vh" }}>
        <ReactFlow
          elements={elements}
          onConnect={onConnect}
          onElementsRemove={onElementsRemove}
          nodesDraggable={false}
          paneMoveable={false}
          zoomOnScroll={false}
          zoomOnPinch={false}
          zoomOnDoubleClick={false}
          onLoad={(reactFlowInstance) => {
            reactFlowInstance.fitView();
          }}
          snapToGrid={true}
          snapGrid={[15, 15]}
        />
      </div>
    </ReactFlowProvider>
  );
};
