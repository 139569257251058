import * as React from "react";
import { Modal } from "react-responsive-modal";

import { getImage } from "gatsby-plugin-image";
import listify from "listify";

import {
  Avatar,
  type ISiteLinkProps,
  SiteLink,
  Tag,
  TagLabel,
  notEmpty,
} from "@parataxic/shared-ui";

import { PopoverSiteLink } from "./Popover";

import "react-responsive-modal/styles.css";
import "./Component.css";

export type PersonInterface =
  | GatsbyTypes.MdxPsychologist
  | GatsbyTypes.Maybe<GatsbyTypes.MdxPsychologist>
  | GatsbyTypes.PsychologistAvatarFragment
  | GatsbyTypes.Maybe<GatsbyTypes.PsychologistAvatarFragment>
  | null;

export const getDescriptionFromPsychologist = (
  psychologist: GatsbyTypes.SitePageContextPsychologist,
): string => {
  let description = "";
  if (psychologist.birthDay) {
    description = `Born ${psychologist.birthDay}`;
    if (psychologist.birthPlace) {
      description += ` in ${psychologist.birthPlace}`;
    }
  } else {
    if (psychologist.birthPlace) {
      description = `From ${psychologist.birthPlace}`;
    }
  }
  if (psychologist.lastName && psychologist?.knownFor != null) {
    if (description.length > 0) {
      description += ", ";
    }
    const knownFor: GatsbyTypes.SitePageContextPdfCreatorsKnownFor[] =
      psychologist?.knownFor
        ? psychologist.knownFor
            .filter(notEmpty)
            .filter((known) => known?.title || false)
        : [];
    const knownForTitles = knownFor.map(({ title }) => title);
    if (knownForTitles && psychologist.fullName) {
      description += `${psychologist.fullName} is involved with ${listify(
        knownForTitles,
      )}`;
    }
  }
  return description;
};

type PsychologistNameFormatOptions = "lastName" | "fullName" | "handle";

export const getPsychologistFullName = (person?: PersonInterface): string => {
  if (!person) {
    return "Unknown person";
  }
  if (person.fullName) {
    return person.fullName;
  }
  let fullName = person.firstName;
  if (person.middleName) {
    fullName += ` ${person.middleName}`;
  }
  fullName += ` ${person.lastName}`;
  return fullName;
};

export const getPsychologistName = (person?: PersonInterface): string => {
  if (!person) {
    return "Unknown person";
  }
  const fullName = getPsychologistFullName(person);
  return person.handle ? person.handle : fullName;
};

export const PsychologistCard: React.FC<{ person: PersonInterface }> = ({
  person,
}) => {
  const fullName = getPsychologistFullName(person);
  const birthYear = person?.birthDay;
  const deathYear = person?.deathDay;
  const knownFor = person?.knownFor?.[0] ?? null;
  const avatarProps = {
    ...(person?.avatarImageSrc
      ? {
          image: getImage(person.avatarImageSrc),
        }
      : {}),
  };

  return (
    <div className="psychologist-card">
      <div className="psychologist-card__avatar">
        <Avatar alt={fullName} loading="eager" {...avatarProps} />
      </div>
      <div className="psychologist-card__title">
        <SiteLink
          href={person?.slug}
          className="psychologist-card__title__link"
        >
          {" "}
          {fullName}{" "}
        </SiteLink>
      </div>
      {birthYear && deathYear && (
        <div className="psychologist-card__dates">
          {birthYear}-{deathYear}
        </div>
      )}
      {knownFor && (
        <span className="psychologist-card__known-for">
          <SiteLink href={knownFor.slug}>{knownFor.title}</SiteLink>
        </span>
      )}
    </div>
  );
};

interface IPsychModalProps {
  isOpen: boolean;
  onClose: () => void;
  modalTitle: string;
}
const PsychModal: React.FC<IPsychModalProps> = ({
  children,
  isOpen,
  onClose,
  modalTitle,
}) => (
  <Modal
    open={isOpen}
    onClose={onClose}
    center
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
  >
    <h2 id="modal-title">{modalTitle}</h2>
    <div id="modal-description">{children}</div>
  </Modal>
);

interface IPersonProps extends ISiteLinkProps {
  person: GatsbyTypes.Maybe<PersonInterface>;
  nameFormat?: PsychologistNameFormatOptions;
}

interface IPsychologistTagProps extends IPersonProps {
  showAvatar: boolean;
  showPopover: boolean;
  linkProps?: React.ComponentProps<typeof SiteLink>;
  tagProps?: React.ComponentProps<typeof Tag>;
  linkStyle?: "tag" | "link";
}
export const PsychologistTag: React.FunctionComponent<
  IPsychologistTagProps
> = ({
  person,
  showAvatar = true,
  showPopover = true,
  nameFormat = "handle",
  linkStyle = "tag",
  linkProps = {},
  tagProps = {},
}) => {
  const handle =
    nameFormat === "handle"
      ? getPsychologistName(person)
      : getPsychologistFullName(person);

  linkProps.href = person?.slug;

  const passRef = showPopover;

  if (passRef) {
    const initRef = React.useRef<HTMLElement>();
    tagProps.ref = initRef;
  }

  const Wrapper = ({ children, ...props }) =>
    linkStyle === "tag" ? <Tag {...props}>{children}</Tag> : <>{children}</>;
  const InnerWrapper = ({ children, ...props }) =>
    linkStyle === "tag" ? (
      <TagLabel {...props}>{children}</TagLabel>
    ) : (
      <>{children}</>
    );
  return (
    <PopoverSiteLink
      style={{
        opacity: 0.7,
      }}
      {...linkProps}
    >
      <Wrapper {...(linkStyle === "tag" ? tagProps : {})}>
        {showAvatar && (
          <Avatar
            title={handle}
            height={"2rem"}
            width={"2rem"}
            style={{ marginRight: "var(--space-5)" }}
            loading="eager"
            image={getImage(person?.avatarImageSrc)}
          />
        )}
        <InnerWrapper
          maxWidth={"8rem"}
          whiteSpace={"pre-line"}
          textAlign="center"
          fontWeight="500"
        >
          {handle}
        </InnerWrapper>
      </Wrapper>
    </PopoverSiteLink>
  );
};

interface IPsychologistTagListProps
  extends React.ComponentProps<typeof PsychologistTag> {
  persons: readonly PersonInterface[];
  delimiter: string;
  usePopup?: boolean;
  popupTitle?: string;
}
export const PsychologistTagList: React.FunctionComponent<
  IPsychologistTagListProps
> = ({
  persons,
  showPopover,
  showAvatar,
  delimiter = ", ",
  usePopup = false,
  popupTitle = "",
  linkProps,
  tagProps,
}) => {
  if (!persons) {
    return null;
  }
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const onClose = () => setOpen(false);
  const MoreBtn: React.FC = () => (
    <SiteLink
      href="#"
      onClick={(e: React.MouseEvent) => {
        e.preventDefault();
        setOpen(true);
      }}
      {...linkProps}
    >
      &nbsp;and {persons.length - 1} others
    </SiteLink>
  );
  return (
    <>
      {usePopup && persons.length > 1 && (
        <PsychModal isOpen={isOpen} onClose={onClose} modalTitle={popupTitle}>
          {persons.map((creator: PersonInterface, idx: number) => [
            idx > 0 && (
              <PsychologistTag
                person={creator}
                key={idx}
                showPopover={showPopover}
                showAvatar={showAvatar}
                tagProps={tagProps}
                linkProps={linkProps}
              />
            ),
          ])}
        </PsychModal>
      )}
      {persons.map((creator: PersonInterface, idx: number) => [
        idx > 0 && delimiter,
        (!usePopup || (usePopup && idx < 1)) && (
          <PsychologistTag
            person={creator}
            key={idx}
            showPopover={showPopover}
            showAvatar={showAvatar}
            linkStyle={"link"}
            tagProps={tagProps}
            linkProps={linkProps}
          />
        ),
        usePopup && idx === 1 && <MoreBtn key={idx} />,
      ])}
    </>
  );
};
