import * as React from "react";

import { Link } from "gatsby";
import {
  type IGatsbyImageData,
  GatsbyImage as Img,
  getImage,
} from "gatsby-plugin-image";

import "@parataxic/shared-ui/src/styles/TopBar.css";

const MenuItem: React.FC<React.HTMLProps<HTMLDivElement>> = ({
  children,
  ...props
}) => (
  <div className="menu-item" {...props}>
    {children}
  </div>
);

interface IProps {
  logo?: IGatsbyImageData;
  siteTitle?: string;
}

export const TopBar: React.FC<IProps> = ({ logo }) => {
  return (
    <header className="top-bar">
      <div className="top-bar-inner">
        <div className="logo-item">
          <Link to="/" className="logo-item--link">
            {logo && <Img image={getImage(logo)} alt="Home" />}
          </Link>
        </div>
        <MenuItem>
          <Link to="/read/">Papers</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/concepts/">Concepts</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/watch/">Video</Link>
        </MenuItem>
      </div>
    </header>
  );
};

export default TopBar;
