/* eslint-disable react/display-name */
import * as React from "react";

import { type Components, MDXProvider } from "@mdx-js/react";
import Slugger from "github-slugger";

import { SiteLink } from "../components/Link";

const MDXMarkdownStyling: React.FC<React.HTMLProps<HTMLDivElement>> = (
  props,
) => <div className="mdx" {...props} />;

const Pre = (props: React.HTMLProps<HTMLPreElement>) => <pre {...props} />;

const Table = (props: React.HTMLProps<HTMLTableElement>) => (
  <div className="table-container">
    <table {...props} />
  </div>
);

const THead = (props: React.HTMLProps<HTMLTableHeaderCellElement>) => (
  <th {...props} />
);

const TData = (props: React.HTMLProps<HTMLTableDataCellElement>) => (
  <td {...props} />
);

const LinkIcon = (props: React.HTMLProps<HTMLDivElement>) => (
  <div {...props}>
    <svg
      aria-hidden="true"
      height="16"
      version="1.1"
      viewBox="0 0 16 16"
      width="16"
    >
      <path
        fillRule="evenodd"
        d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
      />
    </svg>
  </div>
);

const MDXComponents: {
  [key: string]: React.ComponentType;
} = {
  h1: ({ children, ...props }) => (
    <h1
      // fontSize="2rem"
      // fontWeight="400"
      // lineHeight={1.3}
      // my="1em"
      {...props}
    >
      {children}
      <hr />
    </h1>
  ),
  h2: ({ children, ...props }) => {
    const slug = new Slugger().slug(children as string);
    return (
      <h2 id={slug} className="heading" {...props}>
        <span className="heading-link--container">
          {children}
          <a href={`#${slug}`} className="heading-link">
            <LinkIcon />
          </a>
        </span>
      </h2>
    );
  },
  h3: ({ children, ...props }) => {
    const slug = new Slugger().slug(children as string);
    return (
      <h3 id={slug} className="heading" {...props}>
        <span className="heading-link--container">
          {children}
          <a href={`#${slug}`} className="heading-link">
            <LinkIcon />
          </a>
        </span>
      </h3>
    );
  },
  h4: ({ children, ...props }) => {
    const slug = new Slugger().slug(children as string);
    return (
      <h4 id={slug} className="heading" {...props}>
        <span className="heading-link--container">
          {children}
          <a href={`#${slug}`} className="heading-link">
            <LinkIcon />
          </a>
        </span>
      </h4>
    );
  },
  h5: ({ children, ...props }) => {
    const slug = new Slugger().slug(children as string);
    return (
      <h5 id={slug} className="heading" {...props}>
        <span className="heading-link--container">
          {children}
          <a href={`#${slug}`} className="heading-link">
            <LinkIcon />
          </a>
        </span>
      </h5>
    );
  },
  h6: ({ children, ...props }) => {
    const slug = new Slugger().slug(children as string);
    return (
      <h6 id={slug} className="heading" {...props}>
        <span className="heading-link--container">
          {children}
          <a href={`#${slug}`} className="heading-link">
            <LinkIcon />
          </a>
        </span>
      </h6>
    );
  },
  inlineCode: (props) => <code {...props} />,
  code: (props) => <code className="block" {...props} />,
  pre: Pre,
  kbd: (props) => <kbd {...props} />,
  br: (props) => <span className="mdx-linebreak" {...props} />,
  hr: (props) => <hr {...props} />,
  table: Table,
  th: THead,
  td: TData,
  a: ({ children, ...props }) => <SiteLink {...props}>{children}</SiteLink>,
  p: (props) => <p {...props} />,
  ul: (props) => <ul {...props} />,
  ol: (props) => <ol {...props} />,
  blockquote: ({ children, ...props }) => (
    <blockquote {...props}>
      <div className="blockquote-inner">{children}</div>
    </blockquote>
  ),
};

const MyMDXProvider: React.FC<{
  components?: Components;
}> = ({ children, components = {}, ...rest }) => (
  <MDXMarkdownStyling {...rest}>
    <MDXProvider components={{ ...MDXComponents, ...components }}>
      {children}
    </MDXProvider>
  </MDXMarkdownStyling>
);
export default MyMDXProvider;
