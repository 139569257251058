import * as React from "react";

import { getImage } from "gatsby-plugin-image";

import { Avatar, SiteLink } from "@parataxic/shared-ui";

import { PopoverSiteLink } from "./Popover";
import { type PersonInterface, getPsychologistFullName } from "./Psychologist";

import "./Component.css";

export const cardLinkProps: React.CSSProperties = {
  color: "gray.200",
};

export const TheoryCreatorList: React.FC<
  React.HTMLProps<HTMLDivElement> & {
    persons: readonly PersonInterface[] | undefined;
    linkProps?: React.ComponentProps<typeof PopoverSiteLink>;
    possessiveNoun: boolean;
  }
> = ({ persons, linkProps = {}, possessiveNoun = true, ...props }) => {
  if (!persons) {
    return null;
  }
  const personCount = persons.length;

  return (
    <div className={`theory-list ${props.className}`} {...props}>
      {persons.map((person, idx) => [
        idx > 0 &&
          (persons.length > 1 && personCount - 1 === idx ? (
            <>,&nbsp;and&nbsp;</>
          ) : (
            <>,&nbsp;</>
          )),
        <PopoverSiteLink href={person?.slug} key={idx} {...linkProps}>
          {getPsychologistFullName(person)}
          {possessiveNoun && personCount - 1 === idx && "'s"}
        </PopoverSiteLink>,
      ])}
    </div>
  );
};

export const extractTitlesFromTheory = (
  theory: GatsbyTypes.MdxTheory,
): {
  defaultTitle: string;
  isNameInTheory: boolean;
  pageTitle: string;
} => {
  // To do, write a test for it
  const isNameInTheory = theory.authors.some((author) => {
    if (!author) {
      return false;
    }
    return theory?.title.includes(author?.lastName);
  });
  const isObjectRelationsTheory =
    theory.school.id === "object-relations" || theory?.title.includes("Object");
  const isFamilyTheory =
    theory?.title.includes("Family") ||
    theory?.title.includes("Transformational");
  const isMultipleAuthors = theory.authors.length > 1;
  const customTitle = theory?.namedTitle;

  // todo add this to the theory.yaml file
  // and also add "social psychological", "psychological theory" for stuff like tmt
  const defaultTitle = isObjectRelationsTheory
    ? "Object Relations Theory"
    : isFamilyTheory
      ? "Approach to Family Therapy"
      : "Theory of Personality";

  let pageTitle = "";
  if (isNameInTheory) {
    // If has name in theory, e.g. "Object Relation Theory (Kernberg)"
    // e.g. "Otto Kernberg's Object Relations Theory"
    // Erich Fromm's Theory of Personality
    if (isMultipleAuthors) {
      pageTitle = defaultTitle;
    } else if (theory.authors?.[0]) {
      pageTitle = `${theory.authors[0].fullName}'s ${defaultTitle}`;
    }

    if (customTitle) {
      // e.g. Psychoanalysis - Sigmund Freud's Theory of Personality
      pageTitle = `${customTitle} - ${pageTitle}`;
    }
  } else {
    pageTitle = `${customTitle || theory.title} - ${
      theory.authors[0].fullName
    }'s ${defaultTitle}`;
  }
  return {
    defaultTitle,
    isNameInTheory,
    pageTitle,
  };
};

export const TheoryCard: React.FC<{
  mdx: GatsbyTypes.Mdx;
  theory: GatsbyTypes.MdxTheory;
}> = ({ mdx, theory }) => {
  const person = theory.authors[0];
  const fullName = getPsychologistFullName(person);
  const avatarProps = {
    ...(person?.ogImage
      ? {
          image: getImage(person?.ogImage),
        }
      : {}),
  };

  return (
    <div className="theory-card">
      <div className="theory-card__avatar">
        <Avatar alt={fullName} loading="eager" {...avatarProps} />
      </div>
      {theory.authors.length > 0 && (
        <TheoryCreatorList
          className="theory-card__grid__theory-creator-list"
          persons={theory?.authors}
          linkProps={{
            className: "theory-card__grid__theory-creator-list__link",
          }}
        />
      )}
      <div className="theory-card__title">
        <SiteLink href={theory.slug} className="theory-card__title__link">
          {theory.title}{" "}
        </SiteLink>
      </div>

      {mdx.frontmatter?.description && (
        <div className="theory-card__description">
          {mdx.frontmatter.description}
        </div>
      )}
    </div>
  );
};
