import * as React from "react";

import "../styles/Paper.css";

export const Paper = ({
  as: _as,
  ...props
}: React.HTMLProps<HTMLDivElement>): React.ReactElement => {
  if (_as === "main") {
    return <main className="paper" {...props} />;
  }
  return <div className="paper" {...props} />;
};

export const RaisedPaper = (
  props: React.HTMLProps<HTMLDivElement>,
): React.ReactElement => {
  return <div className="paper paper--raised" {...props} />;
};

export default Paper;
