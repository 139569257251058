import * as React from "react";

import { graphql, useStaticQuery } from "gatsby";

import { LayoutProvider, Paper, SEO, SiteLink } from "@parataxic/shared-ui";

import { TopBar } from "../components/Layout/TopBar";

import "@fontsource/ibm-plex-sans";
import "@fontsource/ibm-plex-sans/400-italic.css";
import "@fontsource/ibm-plex-sans/500.css";
import "@fontsource/ibm-plex-sans/600.css";
import "@fontsource/merriweather";
import "@parataxic/shared-ui/src/styles/Layout.css";
import "www/src/styles/global.css";

export const Layout: React.FC = ({ children, ...props }) => {
  const { logo, ogImage, site } = useStaticQuery<GatsbyTypes.RootLayoutQuery>(
    graphql`
      query RootLayout {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        logo: file(name: { eq: "logo-white-rounded" }, ext: { eq: ".png" }) {
          childImageSharp {
            gatsbyImageData(width: 40, placeholder: NONE, layout: FIXED)
          }
        }
        ogImage: file(name: { eq: "logo-white-rounded" }, ext: { eq: ".png" }) {
          childImageSharp {
            gatsbyImageData(width: 512, placeholder: BLURRED, layout: FIXED)
          }
        }
      }
    `,
  );

  const metadata: Partial<React.ComponentProps<typeof SEO>> = {
    title: props?.pageContext?.mdx?.frontmatter?.title,
    description: props?.pageContext?.mdx?.frontmatter?.description,
  };

  const ogImageData =
    props?.pageContext?.theory?.authors?.[0]?.ogImage ??
    props?.pageContext?.term?.theories?.[0]?.authors?.[0]?.ogImage ??
    props?.pageContext?.psychologist?.ogImage;
  if (ogImageData) {
    metadata.imageData = ogImageData;
  }

  return (
    <LayoutProvider siteMetadata={site.siteMetadata}>
      <TopBar siteTitle={site?.siteMetadata?.title} logo={logo} />
      <SEO site={site} imageData={ogImage} {...metadata} />
      <Paper as="main" className="content-paper">
        {children}
      </Paper>
      <div className="footer">
        <div className="footer-links">
          <SiteLink href="/about/" className="footer-link">
            About
          </SiteLink>
          <SiteLink href="/study/" className="footer-link">
            Studying and learning psychoanalysis
          </SiteLink>
        </div>
      </div>
    </LayoutProvider>
  );
};

export default Layout;
