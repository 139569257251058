exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-quotes-mdx": () => import("./../../../src/pages/quotes.mdx" /* webpackChunkName: "component---src-pages-quotes-mdx" */),
  "component---src-templates-book-list-tsx": () => import("./../../../src/templates/BookList.tsx" /* webpackChunkName: "component---src-templates-book-list-tsx" */),
  "component---src-templates-book-tsx": () => import("./../../../src/templates/Book.tsx" /* webpackChunkName: "component---src-templates-book-tsx" */),
  "component---src-templates-mdx-page-tsx": () => import("./../../../src/templates/MDXPage.tsx" /* webpackChunkName: "component---src-templates-mdx-page-tsx" */),
  "component---src-templates-mdx-term-page-tsx": () => import("./../../../src/templates/MDXTermPage.tsx" /* webpackChunkName: "component---src-templates-mdx-term-page-tsx" */),
  "component---src-templates-pdf-list-tsx": () => import("./../../../src/templates/PDFList.tsx" /* webpackChunkName: "component---src-templates-pdf-list-tsx" */),
  "component---src-templates-pdf-tsx": () => import("./../../../src/templates/PDF.tsx" /* webpackChunkName: "component---src-templates-pdf-tsx" */),
  "component---src-templates-psychologist-tsx": () => import("./../../../src/templates/Psychologist.tsx" /* webpackChunkName: "component---src-templates-psychologist-tsx" */),
  "component---src-templates-theory-list-tsx": () => import("./../../../src/templates/TheoryList.tsx" /* webpackChunkName: "component---src-templates-theory-list-tsx" */),
  "component---src-templates-theory-tsx": () => import("./../../../src/templates/Theory.tsx" /* webpackChunkName: "component---src-templates-theory-tsx" */),
  "component---src-templates-video-list-tsx": () => import("./../../../src/templates/VideoList.tsx" /* webpackChunkName: "component---src-templates-video-list-tsx" */),
  "component---src-templates-video-tsx": () => import("./../../../src/templates/Video.tsx" /* webpackChunkName: "component---src-templates-video-tsx" */)
}

